nav {
    height: 4rem;
    width: 100vw;
    background: var(--pl-5);
    display: grid;
    place-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    border-bottom: 4px solid var(--pl-8);
    font-size: 18px;
}

/* only shows on medium and small screens */
.nav__toggle-btn {
    display: none;
}

.nav__container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.season-menu {
    display: flex;
    padding-left: .5rem;
    /* background-color: red; */
    width: 100%;
}

.season-list {
    display: flex;
}

.season-option {
    align-items: center;
    transition: all 400ms ease-in-out;
    opacity: 0;
}

.season-menu:hover .season-option:not(#op_0) {
    opacity: 100;
    padding: 0 1rem;
}

.season-option h3 {
    transition: all 300ms ease-in-out;
}

.season-option:hover h3 {
    color: var(--pl-8)
}

.season-option#op_0 {
    opacity: 100;
}

.season-menu:hover {
    cursor: pointer;
}

.logo {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-left: 1.5rem;
}

.logo h2 {
    color: var(--pl-4);
    padding-left: 1rem;
    font-size: 30px;
    font-weight: 900;
    text-shadow: 3px 2px 0 var(--pl-5);
}

.logo img {
    width: 40px;
    height: 2.8rem;
    border: 2px solid var(--pl-5);
    border-radius: 3rem;
}

.nav__links {
    display: flex;
    gap: 3.5rem;
    align-items: center;
    padding-right: 3rem;
    /* this 100% height was added outside tutorial */
    height: 100%;
}

.nav__links li {
    /* this code below was added outside tutorial */
    height: 100%;
}

.nav__links a {
    /* transition: var(--transition); */
    transition: all 200ms ease-in-out;
    /* This 100% height was added outside tutorial */
    font-weight: 900;
    /* font-size: 125%; */
    height: 100%;
    display: flex;
    align-items: center;
}

.nav__links a:hover {
    color: var(--pl-8);
}

.active-nav {
    position: relative;
    color: var(--pl-8);
}

.active-nav::after {
    content: '';
    display: none; /* changed */
    width: 1.2rem;
    height: 1.2rem;
    background: var(--pl-3);
    position: absolute;
    left: calc(50% - 0.6rem);
    transform: rotate(45deg);
    /* the code below was added outside tutorial */
    bottom: -0.5rem;
}

.pageMode-toggle {
    color: var(--pl-9);
    font-size: 1.4rem;
    font-weight: 900;
    font-family: bahnschrift;
    min-width: 60px;
    text-align: center;
    vertical-align: middle;
    margin-right: 3rem;
    border-radius:10px;
    background: transparent;
    transition: all 200ms ease-in-out;
    -webkit-border-radius:10px;
}

.pageMode-toggle:hover {
    color: var(--pl-4);
}

/* MEDIA QUERIES (medium and small screens) */
@media screen and (max-width: 1024px) {
    .nav__toggle-btn {
        display: inline-block;
        background: transparent;
        font-size: 1.8rem;
        cursor: pointer;
    }

    .nav__toggle-btn svg {
        color: var(--main-white);
    }

    .nav__links {
        position: absolute;
        top: 100%;
        right: 0;
        flex-direction: column;
        gap: 0;
        perspective: 400px;
    }

    .active-nav, .active-nav::after {
        display: none;
    }

    .nav__links li {
        height: 2.7rem;
        width: 100%;
        background-color: var(--pl-5);
        /* box-shadow: -2rem 2rem 5rem rgba(0, 0, 0, 0.5); */
        animation: navAnimation 600ms ease forwards;
        transform: rotateX(90deg);
        opacity: 0;
        transform-origin: top;
    }

    .nav__links li:nth-child(2) {
        animation-delay: 200ms;
    }

    .nav__links li:nth-child(3) {
        animation-delay: 400ms;
    }

    .nav__links li:nth-child(4) {
        animation-delay: 600ms;
    }

    .nav__links li:nth-child(5) {
        animation-delay: 800ms;
    }

    .nav__links li:nth-child(6) {
        animation-delay: 1s;
    }

    .nav__links li:nth-child(7) {
        animation-delay: 1.2s;
    }


    @keyframes navAnimation {
        to {
            transform: rotateX(0);
            opacity: 1;
        }
    }

    .nav__links li a {
        background-color: var(--color-primary);
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 1rem 5rem 1rem 3rem;
    }

    .show__nav {
        display: flex;
    }

    .hide__nav {
        display: none;
    }
}