body {
    font-size: 14px;
    line-height: 1.7;
}

@media screen and (max-width: 580px) {
    body {
        font-size: 16px;
        line-height: 22px;
    }

    .leaderboard {
        display: block;
    }

    .player-standing {
        padding: 14px 0 7px;
        display: block;

        &.header {
            padding: 0;
            height: 6px;
        }

        &.header .row-item {
            display: none;
        }
    }

    .row-item {
        padding: 2px 16px;
        display: block;
    }
}

.leaderboard .row-item {
    white-space: nowrap;
    padding: 3px 25px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}

.icon-set {
    /* display: flex;
    flex: 1; */
    justify-content: center;
    display: grid;
    width: 100%;
}

.standings-container {
    display: flex;
    margin: 5rem auto;
    justify-content: center;
    align-items: center;
    max-width: 1600px;
}

.leaderboard {
    border-collapse: collapse;
    margin: 0 0 40px 0;
    width: auto;
    display: table;
    background-color: var(--main-black);
    border-radius: 4px;
}

.leaderboard .header {
    font-size: 115%;
}

.header .row-item {
    padding: 6px 30px;
}

.player-standing.rank-one {
    background-color: #FF86C8;
    color: var(--pl-5);
    font-weight: bold;
}

.player-standing.rank-omega {
    background-color: var(--pl-9);
    font-weight: bolder;
}
.player-standing.rank-alpha {
    background-color: #70ee9c;
    color: var(--pl-5);
    font-weight: bolder;
}
.player-standing.rank-beta {
    background-color: #a6f4dc;
    color: var(--pl-5);
    font-weight: bolder;
}
.player-standing.rank-gamma {
    background-color: #120236;
    font-weight: bolder;
}
.player-standing.rank-delta {
    background-color: #1B0122;
    font-weight: bolder;
}


.player-standing {
    display: table-row;
    background-color: var(--second-black);
    border: 2px solid var(--main-white);

    transition: all 200ms ease-in-out;

    &.header {
        font-weight: 900;
        background-color: var(--main-black);
    }
}

.player-standing:not(.header) {
    font-size: 16px;
}

.player-standing:not(.header):hover {
    background-color: var(--pl-8);
}

.op-icon {
    width: 40px;
    grid-row: 1;
}