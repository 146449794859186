.main__header {
    width: 100vw;
    height: 50vw;
    display: grid;
    place-items: center;
}

.main__header-container {
    height: 100%;
    width: 80%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: space-between;
    gap: 6rem;
    max-width: 1920px;
    margin-inline: auto;
}

.main__header-left {
    margin-top: -3rem;
}

.main__header-left h4 {
    margin-bottom: 1rem;
    color: var(--main-white);
    font-weight: 700;
}

.main__header-left p {
    margin: 1rem 0 3.5rem;
    font-size: 1.15rem;
}