.about-container {
    margin: 5rem auto;
    width: 95vw;
    justify-content: left;
}

h1 {
    font-size: 2rem;
    color: var(--pl-4);
    padding: 1rem 0rem;
}

h2 {
    font-size: 1.4rem;
    color: var(--pl-9);
    padding: 0.3rem 0.4rem;
}

h3 {
    color: var(--pl-4);
    padding: 0.6rem 1rem;
}

.entry-form {
    padding-left: 0.6rem;
    font-size: 18px;
    transition: all 200ms ease-in-out;
    /* color: var(--pl-11); */
    color: #8CF3F3;
}

.entry-form:hover {
    color: var(--pl-8);
    padding-left: 0.8rem;
}

span {
    padding-left: 0.8rem;
    font-size: medium;
}