*, *::before, *::after {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
}

@font-face {
    font-family: bahnschrift;
    src: url(./font/BAHNSCHRIFT.TTF) format("truetype-variations");
}

:root {
    --primary-hue: 210;
    --gray-hue: 240;
    --main-black: #08090A;
    --second-black: #101113;
    --main-white: #F4F7F5;
    --main-red: #DB324D;
    --green-1: #9fff9f;
    --green-2: #c2ff9a;
    --white-2: #dadada;

    --pl-1: #140D16;
    --pl-2: #372D39;
    --pl-3: #8DC7EA;
    --pl-4: #F4F8FA;
    --pl-5: #08090A;
    --pl-6: #BDC0C0;
    --pl-7: #191424;
    --pl-8: #5E2BFF;
    --pl-9: #DB324D;
    --pl-10: #9A94BC;
    --pl-11: #A6F4DC;
    --pl-12: #FFFD82;
    --pl-13: #06D6A0;
    --pl-14: #70EE9C;
    --pl-15: #A3E6DF;

    --container-width-lg: 80%;
    --container-width-md: 90%;

    --transition: all 500ms ease;
}

body {
    background: var(--pl-5);
    font-family: bahnschrift;
    color: var(--pl-4);
    line-height: 1.7;
    overflow-x: hidden;
}

.container {
    /* width: var(--container-width-lg); */
    width: 100vw;
    max-width: 1920px;
    margin-inline: auto;
}

h1, h2, h3, h4, h5 {
    line-height: 1.2;
}

a {
    color: var(--pl-4);
}

img {
    display: block;
    object-fit: cover;
    width: 100%;
}

.btn {
    color: var(--main-red);
    width: fit-content;
    margin: 0 auto;
    padding: 0.9rem 2rem;
    background: var(--second-black);
    border-radius: 1.5rem;
    transition: all 200ms ease-in-out;
}